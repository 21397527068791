import { React } from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import {
  useMediaQuery, Paper, Box, Link,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import LogoImage from './LogoImage';
import LogoAndroid from './LogoAndroid';
import LogoIos from './LogoIos';
import image from '../resources/images/background-gootrack.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  sidebar: {
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.primary.main,
    paddingBottom: theme.spacing(5),
    width: theme.dimensions.sidebarWidth,
    [theme.breakpoints.down('lg')]: {
      width: theme.dimensions.sidebarWidthTablet,
    },
    [theme.breakpoints.down('sm')]: {
      width: '0px',
    },
  },
  paper: {
    backgroundColor: '#3C4290',
    backgroundImage: `url(${image})`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    boxShadow: '-2px 0px 16px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 1, 0, 1),
      backgroundColor: '#3C4290',
      backgroundImage: `url(${image})`,
      backgroundSize: 'cover',
    },
  },
  form: {
    backgroundColor: '#fff',
    maxWidth: theme.spacing(47),
    padding: theme.spacing(3),
    width: '90%',
    borderRadius: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
  },
  box: {
    [theme.breakpoints.up('sx')]: {
      padding: theme.spacing(0, 0, 0, 0),
    },
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <main className={classes.root}>
      <div className={classes.sidebar}>
        {!useMediaQuery(theme.breakpoints.down('lg')) && <LogoImage color={theme.palette.secondary.contrastText} />}
      </div>
      <Paper className={classes.paper}>
        <form className={classes.form}>
          {!useMediaQuery(theme.breakpoints.down('lg')) && <LogoImage color={theme.palette.secondary.contrastText} />}
          {children}
        </form>
        <Box className={classes.box} display="flex" sx={{ marginTop: 2 }}>
          <Box sx={{ marginRight: 1 }}>
            <Link
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.app.gootrackrastreamento"
            >
              {!useMediaQuery(theme.breakpoints.down('lg')) && <LogoAndroid color={theme.palette.secondary.contrastText} />}
            </Link>
          </Box>
          <Box sx={{ marginLeft: 1 }}>
            <Link
              target="_blank"
              href="https://apps.apple.com/us/app/gootrack-rastreamento/id6466339751"
            >
              {!useMediaQuery(theme.breakpoints.down('lg')) && <LogoIos color={theme.palette.secondary.contrastText} />}
            </Link>
          </Box>
          <Box>
            <FloatingWhatsApp
              phoneNumber="+5577981326314"
              accountName="Gootrack Rastreamento"
              allowEsc="true"
              allowClickAway="true"
              notification="true"
              statusMessage="Onde estiver...nós localizamos!"
              chatMessage="Olá! Tudo bem por aí? 🤝 Como podemos te ajudar hoje?"
              placeholder="Digite sua mensagem..."
              darkMode="true"
              notificationDelay="60"
              notificationLoop="2"
            />
          </Box>
        </Box>
      </Paper>
    </main>
  );
};

export default LoginLayout;
